import React, { useEffect } from "react";
import useEscolaridad from "../../hooks/useEscolaridad";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { Controller } from "react-hook-form";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { es } from "date-fns/locale";
import BeatLoader from "react-spinners/BeatLoader";
import { subYears } from "date-fns";
import { ContentPasteSearchOutlined } from "@mui/icons-material";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

export default function Escolaridad() {
  const {
    isOpen,
    openModal,
    closeModal,
    onSubmit,
    control,
    register,
    errors,
    handleSubmit,
    loading,
    get_escolaridad,
    maxDate,
    minDate,
  } = useEscolaridad();

  useEffect(() => {
    get_escolaridad().then((res) => {

      if (res.role === "Vendedor" || res.role === "Promotor") {
        if (res.update_birthdate === 0) {
          openModal();
        }
      }
    });
  }, []);

  return (
    <div>
      <Modal
        show={isOpen}
        backdrop="static"
        keyboard={false}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Puntos Naranjas STIHL</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            La exactitud de nuestros registros es importante. Te pedimos
            gentilmente que ingreses tu edad y nivel de escolaridad en el
            siguiente campo.
          </p>

          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Row className="justify-content-start mt-2">
              <Col xs={12}>
                <Form.Group className="mb-3" controlId="cumpleanos">
                  <Form.Label>
                    Fecha de nacimiento <span className="text-danger">*</span>
                  </Form.Label>
                  <Controller
                    rules={{ required: "El campo es obligatorio" }}
                    control={control}
                    name="cumpleanos"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Form.Control
                        inputRef={ref}
                        type="text"
                        autoComplete="off"
                        as={ReactDatePicker}
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        maxDate={maxDate}
                        minDate={minDate}
                        locale={es}
                        placeholderText="Selecciona la fecha"
                        dateFormat="dd-MM-yyyy"
                        error={!!errors.cumpleanos}
                      />
                    )}
                  />

                  <Form.Text className="text-danger">
                    {errors.cumpleanos?.message}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>

            <Row className="justify-content-start mt-2">
              <Col xs={12}>
                <Form.Group className="mb-3" controlId="escolaridad">
                  <Form.Label>
                    Escolaridad <span className="text-danger">*</span>
                  </Form.Label>

                  <Form.Select
                    aria-label="escolaridad"
                    {...register("escolaridad", {
                      required: "El campo es obligatorio.",
                    })}
                  >
                    <option value="">Selecciona una opción</option>
                    <option value="Sin escolaridad">Sin escolaridad</option>
                    <option value="Primaria">Primaria</option>
                    <option value="Secundaria">Secundaria</option>
                    <option value="Preparatorio / Bachillerato">
                      Preparatorio / Bachillerato
                    </option>
                    <option value="Carrera técnica">Carrera técnica</option>
                    <option value="Licenciatura">Licenciatura</option>
                  </Form.Select>
                  <Form.Text className="text-danger">
                    {errors.escolaridad?.message}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <BeatLoader
            color="#f37a1f"
            loading={loading}
            cssOverride={override}
            size={20}
            aria-label="Loading Spinner"
            data-testid="loader"
            speedMultiplier={1}
          />
          <Button
            type="submit"
            variant="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={loading}
          >
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
