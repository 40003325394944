import React, {useContext} from "react";
import {useNavigate, useMatch} from "react-router-dom";
import {NavItem, NavLink} from "reactstrap";
import useUser from "../../hooks/useUser";
import Menu from "components/Menu";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import "utilities/inputs.css";
import SocketRanking from "./SocketRanking";
import Context from "context/UserContext";
import {Helmet} from "react-helmet";
import pic from "assets/images/favicon.ico";
import WhatsApp from "@mui/icons-material/WhatsApp";
import Aviso from "components/notice";
import Escolaridad from "components/Escolaridad";



export default function Header({getTotalItems, setCartOpen}) {
  const {Roles, Puntos, Concentrados} = useUser();
  const {isLogged, logout, IdUser} = useUser();
  const navigate = useNavigate();
  const match = useMatch("/");
  const {setPuntos, setConcentrados} = useContext(Context);

  const handleClick = (e) => {
    e.preventDefault();
    logout();
    navigate("/", {replace: true});
  };

  const goToRoute = (ruta) => {
    navigate("/" + ruta);
  };

  const formatNumber = (number) =>
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

  const formatNumber2 = (number) =>
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);

  const renderLoginButtons = ({isLogged}) => {
    return isLogged ? (
      <>
      <Escolaridad />
        {(Roles === "Admin" && (
          <>
            <NavItem>
              <NavLink
                onClick={() => goToRoute("productos-registrados")}
                className="text-white"
                style={{cursor: "pointer"}}
              >
                Facturas registradas
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                onClick={() => goToRoute("accessories")}
                className="text-white"
                style={{cursor: "pointer"}}
              >
                Accesorios
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={() => goToRoute("admin-depositos")}
                className="text-white"
                style={{cursor: "pointer"}}
              >
                Depositos
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={() => goToRoute("accounts")}
                className="text-white"
                style={{cursor: "pointer"}}
              >
                Cuentas
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={() => goToRoute("promotors")}
                className="text-white"
                style={{cursor: "pointer"}}
              >
                EDMs
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={() => goToRoute("equivalencies")}
                className="text-white"
                style={{cursor: "pointer"}}
              >
                Equivalencias
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={() => goToRoute("download-reports")}
                className="text-white"
                style={{cursor: "pointer"}}
              >
                Descargas
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={handleClick}
                className="text-white"
                style={{cursor: "pointer"}}
              >
                <PowerSettingsNewIcon /> Cerrar sesión
              </NavLink>
            </NavItem>
          </>
        )) ||
          (Roles === "Analista" && (
            <>
              <NavItem>
                <NavLink
                  onClick={() => goToRoute("productos-registrados")}
                  className="text-white"
                  style={{cursor: "pointer"}}
                >
                  Facturas registradas
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  onClick={() => goToRoute("accessories")}
                  className="text-white"
                  style={{cursor: "pointer"}}
                >
                  Accesorios
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  onClick={() => goToRoute("accounts")}
                  className="text-white"
                  style={{cursor: "pointer"}}
                >
                  Cuentas
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  onClick={() => goToRoute("promotors")}
                  className="text-white"
                  style={{cursor: "pointer"}}
                >
                  EDM
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => goToRoute("admin-depositos")}
                  className="text-white"
                  style={{cursor: "pointer"}}
                >
                  Depositos
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => goToRoute("buscar-serial")}
                  className="text-white"
                  style={{cursor: "pointer"}}
                >
                  Buscar Serial
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => goToRoute("editar-vendedor")}
                  className="text-white"
                  style={{cursor: "pointer"}}
                >
                  Editar Vendedor
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => goToRoute("ganadores-mes")}
                  className="text-white"
                  style={{cursor: "pointer"}}
                >
                  Listado de ganadores por mes
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => goToRoute("ganador-norte")}
                  className="text-white"
                  style={{cursor: "pointer"}}
                >
                  Ganadores Region Norte
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => goToRoute("ganador-centro")}
                  className="text-white"
                  style={{cursor: "pointer"}}
                >
                  Ganadores Region Centro
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => goToRoute("ganador-sur")}
                  className="text-white"
                  style={{cursor: "pointer"}}
                >
                  Ganadores Region Sur
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={handleClick}
                  className="text-white"
                  style={{cursor: "pointer"}}
                >
                  <PowerSettingsNewIcon /> Cerrar sesión
                </NavLink>
              </NavItem>
            </>
          )) ||
          ((Roles === "Vendedor" ||
            Roles === "Propietario") && (
            <>
              <NavItem>
                <NavLink
                  onClick={() => goToRoute("registrar-producto")}
                  className="text-white"
                  style={{cursor: "pointer"}}
                >
                  Registra tu venta
                </NavLink>
              </NavItem>
              {Roles === "Vendedor" ? (
                <NavItem>
                  <NavLink
                    onClick={() => goToRoute("compras")}
                    className="text-white"
                    style={{cursor: "pointer"}}
                  >
                    Depósitos
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}
              {Roles === "Vendedor" || Roles === "Propietario" ? (
                <NavItem style={{border: "1px solid #fff", background: "#fff"}}>
                  <NavLink
                    onClick={() => goToRoute("dashboard")}
                    className="text-dark"
                    style={{cursor: "normal", fontWeight: "bold"}}
                  >
                    {formatNumber2(Puntos)} Puntos con candado
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}
              {Roles === "Vendedor" && Concentrados > 0 ? (
                <>
                  <NavItem className="bg-white mx-2">
                    <NavLink
                      className="text-black"
                      role="button"
                      onClick={() => goToRoute("canjear")}
                    >
                      <CurrencyExchangeIcon className="mx-1" />
                      Canjear ${formatNumber(Concentrados)} MXN
                    </NavLink>
                  </NavItem>
                </>
              ) : (
                ""
              )}

              <NavItem>
                <NavLink
                  onClick={handleClick}
                  className="text-white"
                  style={{cursor: "pointer"}}
                >
                  <PowerSettingsNewIcon /> Cerrar sesión
                </NavLink>
              </NavItem>
            </>
          ))}
      </>
    ) : (
      <>
        <NavItem>
          <NavLink
            onClick={() => goToRoute("login")}
            className="text-white"
            style={{cursor: "pointer"}}
          >
            Login
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            onClick={() => goToRoute("register")}
            className="text-white"
            style={{cursor: "pointer"}}
          >
            Crea tu cuenta
          </NavLink>
        </NavItem>
      </>
    );
  };

  const content = match ? null : renderLoginButtons({isLogged});

  //const content = renderLoginButtons({ isLogged });

  return (
    <>
      <Helmet htmlAttributes={{lang: "es"}}>
        <meta charSet="utf-8" />
        <title>Puntos Naranjas STIHL</title>
        <link rel="icon" type="image/png" href={pic} />
        <meta name="description" content="Puntos Naranjas STIHL" />
      </Helmet>
      <header className="gf-header">
        <Menu>{content}</Menu>
      </header>
      <SocketRanking
        IdUser={IdUser}
        setPuntos={setPuntos}
        setConcentrados={setConcentrados}
      />
      {Roles === "Vendedor" ? (
        <>
          <a
            href="https://wa.me/522211048168"
            className="whatsapp"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <WhatsApp className="mx-2" />
          </a>
        </>
      ) : (
        ""
      )}
    </>
  );
}
